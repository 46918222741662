import $ from 'jquery';

if ($('.p-top').length) {
	$(window).on('load', function () {
		if ($(window).outerWidth() > 767) {
			var scrollThreshold = $('.p-top1__ttl').offset().top - 202;
			$('.p-top1__ttl').addClass('is-fixtop');
			$(window).on('scroll', function (e) {
				if ($(this).scrollTop() >= scrollThreshold) {
					$('.p-top1__ttl').removeClass('is-fixtop');
				} else {
					$('.p-top1__ttl').addClass('is-fixtop');
				}
			});
		}
	});
}
